import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { Fragment } from 'react';
import './GridReportTool.css';
import { GetSalesBusiness, RemoveSalesBusiness, StoreSalesBusiness } from '../../../utils/StorageUtil';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';
import {
	CreateSalesCompany,
	CreateSalesCompanyTask,
	DeleteSalesCompanyTask,
	GenerateSEOReportDataGrid,
	GetReportSettings,
	GetSalesCompanyDetails,
	GetSalesCompanyTaskDetails,
	GetSalesCompanyTasks,
	UpdateSalesCompany,
	UpdateSalesCompanyTask,
	UpdateSalesCompanyTaskAuditData,
	UpdateSalesCompanyTaskIndexes
} from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { copyToClipboard, createGuid, slugify } from '../../../utils/Common';
import moment from 'moment';
import html2canvas from 'html2canvas';
import {
	GetBusinessInfoTaskPost,
	GetBusinessProfilePhotos,
	GetBusinessProfilePosts,
	GetBusinessProfileReviews
} from '../../../ReportAPI';

const GridReportTool = () => {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoadingMap, setIsLoadingMap ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ isGeneratingAudit, setIsGeneratingAudit ] = useState(false);
	const [ isUpdatingAudit, setIsUpdatingAudit ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ companyData, setCompanyData ] = useState();
	const [ companyName, setCompanyName ] = useState();
	const [ areaRadius, setAreaRadius ] = useState();
	const [ keywords, setKeywords ] = useState();
	const [ selectedTask, setSelectedTask ] = useState();
	const navigate = useNavigate();
	const [ showGridReport, setShowGridReport ] = useState(false);
	const [ reportTasks, setReportTasks ] = useState();
	const [ dateCreated, setDateCreated ] = useState();
	const [ gridData, setGridData ] = useState();
	const [ auditData, setAuditData ] = useState({});
	const [ reportSettings, setReportSettings ] = useState();
	const { companyId, reportName, reportId, editCompanyId } = useParams();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ changesMade, setChangesMade ] = useState(false);
	const [ auditProfileInfoResponse, setAuditProfileInfoResponse ] = useState();
	const gridMapContainerRef = useRef();
	const emptyApiResponse = {
		response : 'No data found'
	};

	useEffect(() => {
		getReportSettings();
	}, []);

	useEffect(() => {
		if (companyId) {
			const fetchData = async () => {
				await getCompanyDetails(companyId);
				await getCompanyReportTasks(companyId);
				setShowGridReport(true);
			};

			fetchData().catch((error) => {});
		}
	}, []);

	useEffect(
		() => {
			if (editCompanyId) {
				setIsLoadingMap(true);
				setShowGridReport(false);
				getCompanyDetails(editCompanyId);
				getCompanyReportTasks(editCompanyId, true);
			}
		},
		[ editCompanyId ]
	);

	useEffect(
		() => {
			if (reportId) {
				getReportTaskDetails(reportId, true);
				setShowGridReport(true);
			}
		},
		[ reportId ]
	);

	useEffect(() => {
		if (!companyId && !editCompanyId && !reportId) {
			setIsLoadingMap(true);

			let tempBusiness = GetSalesBusiness();

			if (tempBusiness) {
				setSelectedBusiness(tempBusiness);

				setAreaRadius(tempBusiness.isServiceArea ? 7 : 2.5);

				setGridData({
					googlePlaceId : tempBusiness.place_id,
					lat           : tempBusiness.lat,
					lng           : tempBusiness.lng
				});
			}

			setIsLoading(false);
			setIsLoadingMap(false);
		}
	}, []);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoadingMap(false);
			}
		},
		[ window.mapReady ]
	);

	useEffect(
		() => {
			if (selectedBusiness) {
				setGridData({
					googlePlaceId : selectedBusiness.place_id,
					lat           : selectedBusiness.lat,
					lng           : selectedBusiness.lng
				});
			}
		},
		[ areaRadius, selectedBusiness ]
	);

	// useEffect(
	// 	() => {
	// 		if (!isLoading && areaRadius) {
	// 			setChangesMade(true);
	// 		}
	// 	},
	// 	[ areaRadius ]
	// );

	const getCompanyDetails = async (id) => {
		let response = await GetSalesCompanyDetails(id);

		if (response) {
			setCompanyData(response);
			setCompanyName(response.name);

			if (editCompanyId) {
				setGridData({
					googlePlaceId : response.googlePlaceId,
					lat           : response.lat,
					lng           : response.lng
				});
			}
		}
	};

	const getCompanyReportTasks = async (id) => {
		setIsLoading(true);

		let response = await GetSalesCompanyTasks(id);

		if (response && response.length > 0) {
			let tempTasks = response;
			let tempTask;

			if (reportName) {
				tempTasks = tempTasks.filter((x) => x.reportName === reportName);
			}

			if (tempTasks.length > 0) {
				setReportTasks(tempTasks);

				//sort by order index
				tempTasks = tempTasks.sort(function(a, b) {
					return a.orderIndex - b.orderIndex;
				});

				tempTask = tempTasks[0];
				setSelectedTask(tempTask);

				if (editCompanyId) {
					setAreaRadius(tempTask.gridRadius);

					setKeywords(tempTasks.map((task) => task.keyword).join('\n'));

					setIsLoadingMap(false);
				} else {
					await getReportTaskDetails(tempTask.id);
				}
			}
		} else {
			setAreaRadius(5);
		}

		setIsLoading(false);
	};

	const getReportTaskDetails = async (id, isSingleTask = false, isSelectedTask = false) => {
		if (!isSelectedTask) {
			setIsLoading(true);
		}

		let response = await GetSalesCompanyTaskDetails(id);

		if (response) {
			let tempTask = response;

			setDateCreated(tempTask.lastUpdated || tempTask.dateCreated);

			if (isSingleTask) {
				let tempTasks = [ tempTask ];
				setReportTasks(tempTasks);

				setCompanyName(tempTask.salesCompanyName);
			}

			if (tempTask.content) {
				let gridReportData = JSON.parse(tempTask.content);

				if (gridReportData) {
					setGridData({
						googlePlaceId    : gridReportData.googlePlaceId,
						lat              : tempTask.lat,
						lng              : tempTask.lng,
						horizontalPoints : tempTask.gridPoints,
						verticalPoints   : tempTask.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});

					setIsLoadingMap(false);
				}
			} else {
				setIsLoadingMap(false);
			}

			if (tempTask.auditData) {
				setAuditData(JSON.parse(tempTask.auditData));
			}
		} else {
			setIsLoadingMap(false);
		}

		setIsLoading(false);
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	const handleChange = async (e) => {
		if (e.target.name === 'areaRadius') {
			setAreaRadius(e.target.value);
			setChangesMade(true);
		} else if (e.target.name === 'keywords') {
			setKeywords(e.target.value);
		}
	};

	const generateReport = async (e) => {
		e.preventDefault();

		if (!keywords || keywords.trim() === '') {
			toast.error('Enter at least one keyword to continue');
			return;
		}

		let keywordsArrayForReport = [];

		if (editCompanyId && changesMade) {
			//radius has changed, thus create report for all keyword
			keywordsArrayForReport = keywords.split('\n').map((x) => x.trim());
		} else if (editCompanyId && !changesMade) {
			//run reports only for new keywords or for existing also if it's passed more than 7 days
			if (keywords && reportTasks && reportTasks.length > 0) {
				//first check if we changed or delete any of existing keywords
				reportTasks.forEach(async (reportTask) => {
					let existingKeyword = keywords
						.split('\n')
						.find((x) => x.toLowerCase() === reportTask.keyword.trim().toLowerCase());

					if (!existingKeyword) {
						//delete this task because the keyword is not anymore in the list
						const deleteTaskResponse = await DeleteSalesCompanyTask(editCompanyId, reportTask.id);

						if (!deleteTaskResponse) {
							console.log('Failed while delete task: ' + reportTask.id);
						}
					}
				});

				//go through keywords and create or update tasks
				keywords.split('\n').forEach((keyword) => {
					let existingTask = reportTasks.find(
						(x) => x.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
					);

					if (!existingTask) {
						//no existing task for this keyword, so add it to run report keywords list
						keywordsArrayForReport.push(keyword.trim());
					} else {
						let reportDate = existingTask.lastUpdated || existingTask.dateCreated;

						if (moment(reportDate).add(7, 'days').isBefore(moment(), 'day')) {
							//task exists for this keyword, but it was created more than 7 days ago
							keywordsArrayForReport.push(keyword.trim());
						}
					}
				});
			}
		} else {
			keywordsArrayForReport = keywords.split('\n').map((x) => x.trim());
		}

		if (keywordsArrayForReport && keywordsArrayForReport.length > 5) {
			toast.error('You can set max 5 keywords');
			return;
		}

		if (editCompanyId && keywordsArrayForReport.length === 0) {
			//no any keyword changed or all keywords ran in the last 7 days, just redirect to report page
			window.location.href = `${host}/business/${editCompanyId}/reports/details`;
		}

		setIsGenerating(true);
		setIsLoading(true);

		//calculate grid points based on radius
		let gridPoints = 7;

		if (areaRadius > 5) {
			gridPoints = 9;
		} else if (areaRadius > 10) {
			gridPoints = 11;
		}

		let pointDistance = parseFloat(areaRadius * 2 / (gridPoints - 1));
		let companyId;
		let tempCompany;

		if (editCompanyId) {
			const companyResponse = await GetSalesCompanyDetails(editCompanyId);

			if (companyResponse) {
				companyId = companyResponse.id;
				tempCompany = companyResponse;

				if (gridData.lat && gridData.lat !== tempCompany.lat) {
					tempCompany.lat = gridData.lat;
					tempCompany.lng = gridData.lng;

					await UpdateSalesCompany(companyId, tempCompany);
				}
			}
		}

		if (!tempCompany) {
			let email =
				selectedBusiness.name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

			//first create company if it doesn't exist
			let companyData = {
				...selectedBusiness,
				userId            : selectedBusiness.salesUserId ? selectedBusiness.salesUserId : user.id,
				email             : email,
				phone             : selectedBusiness.formatted_phone_number,
				googleCid         : selectedBusiness.cid,
				googlePlaceId     : selectedBusiness.place_id,
				googleBusinessUrl : selectedBusiness.googleBusinessUrl
					? selectedBusiness.googleBusinessUrl
					: selectedBusiness.cid ? 'https://maps.google.com/?cid=' + selectedBusiness.cid : '',
				status            : 'Active'
			};

			const companyResponse = await CreateSalesCompany(companyData);

			if (!companyResponse.success) {
				if (
					companyResponse.data &&
					companyResponse.message.indexOf('already exists') > -1 &&
					companyResponse.data.userId === user.id
				) {
					companyId = companyResponse.data.id;
					tempCompany = companyResponse.data;

					//update lat and lng if necessary
					if (selectedBusiness && selectedBusiness.lat !== tempCompany.lat) {
						tempCompany.lat = selectedBusiness.lat;
						tempCompany.lng = selectedBusiness.lng;

						const updateCompanyResponse = await UpdateSalesCompany(companyId, tempCompany);

						if (!updateCompanyResponse.success) {
							console.log('Company lat/lng not updated. Reason: ' + updateCompanyResponse.message);
						}
					}
				}
			} else {
				tempCompany = companyResponse.data;
				companyId = companyResponse.data.id;
			}
		}

		if (tempCompany) {
			RemoveSalesBusiness();

			//create report
			let reportData = {
				salesUserId   : user && user.isSalesUser ? user.id : null,
				campaignId    : null,
				keywords      : keywordsArrayForReport,
				lat           : tempCompany.lat,
				lng           : tempCompany.lng,
				googlePlaceId : tempCompany.googlePlaceId,
				gridPoints    : gridPoints,
				pointDistance : pointDistance,
				gridApiType   : 'dseo'
			};

			const reportResponse = await GenerateSEOReportDataGrid(reportData);

			if (reportResponse.success) {
				let tempReport = reportResponse;

				let reportShortName = moment().format('MM-DD-YY-HH-mm');

				let keywordIndex = 0;

				//finally, create or update task for every keyword
				for (const keyword of keywordsArrayForReport) {
					let gridResponseData = tempReport.gridResponse.data.filter(
						(k) => k.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
					);

					keywordIndex++;

					let taskData = {
						salesCompanyId            : tempCompany.id,
						keyword                   : keyword,
						lat                       : tempCompany.lat,
						lng                       : tempCompany.lng,
						gridPoints                : gridPoints,
						gridPointsDistanceInMiles : pointDistance,
						gridRadius                : areaRadius,
						reportName                : reportShortName,
						orderIndex                : keywordIndex,
						content                   : JSON.stringify({
							googlePlaceId : tempCompany.googlePlaceId,
							gridResponse  : {
								lat              : tempCompany.lat,
								lng              : tempCompany.lng,
								horizontalPoints : gridPoints,
								verticalPoints   : gridPoints,
								data             : gridResponseData
							}
						})
					};

					let isTaskEdit = false;

					if (editCompanyId) {
						//update existing task with new grid report data
						let currentTask;

						if (reportTasks && reportTasks.length > 0) {
							currentTask = reportTasks.find(
								(x) => x.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
							);
						}

						if (currentTask && currentTask.id) {
							isTaskEdit = true;
							taskData.id = currentTask.id;
						} else {
							//task keyword not found, create new
							taskData.id = createGuid();
						}
					} else {
						//create new task
						taskData.id = createGuid();
					}

					let taskResponse;

					if (isTaskEdit) {
						taskResponse = await UpdateSalesCompanyTask(taskData.id, taskData);
					} else {
						taskResponse = await CreateSalesCompanyTask(taskData);
					}

					if (taskResponse.success) {
					}
				}

				//finally, we need to set order index for all tasks based on keywords order in the list
				let tasks = await GetSalesCompanyTasks(tempCompany.id);

				if (tasks && tasks.length > 0) {
					tasks.forEach((task) => {
						let keywordIndexFound = keywords
							.split('\n')
							.findIndex((x) => x.toLowerCase() === task.keyword.trim().toLowerCase());

						if (keywordIndexFound > -1) {
							task.orderIndex = keywordIndexFound + 1;
						}
					});

					//update indexes
					await UpdateSalesCompanyTaskIndexes(tasks);
				}

				window.location.href = `${host}/business/${tempCompany.id}/reports/details`;
			} else {
				toast.error(reportResponse.message);
				console.error(reportResponse);
				setIsGenerating(false);
				setIsLoading(false);
			}
		} else {
			setIsGenerating(false);
			setIsLoading(false);
		}
		//}
		//}
	};

	const handleSelectReportTask = async (task) => {
		setIsLoadingMap(true);
		setSelectedTask(task);
		await getReportTaskDetails(task.id, false, true);
	};

	const getReportShareLink = () => {
		var url = new URL(`${host}/public/reports/${selectedTask.id}`);

		copyToClipboard(url.href);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 1000);
	};

	const exportMapAsImage = () => {
		setIsLoadingMap(true);

		let companySlug = slugify(companyName);
		let date = moment().format('MMDDYYYYHHmm');
		let imageName = showGridReport
			? `exported-grid-${companySlug}-${slugify(selectedTask.keyword)}-${date}.png`
			: `exported-map-${companySlug}-${date}.png`;

		let $mapContainer = showGridReport
			? document.querySelector('#report-map-0')
			: document.querySelector('#report-map-preview');

		if ($mapContainer) {
			gridMapContainerRef.current.classList.add('no-transition');

			html2canvas($mapContainer, { useCORS: true })
				.then((canvas) => {
					var imgSrc = canvas.toDataURL('image/png');

					var link = document.createElement('a');
					if (typeof link.download !== 'string') {
						window.open(imgSrc);
					} else {
						link.href = imgSrc;
						link.download = imageName;
						link.click();
						//accountForFirefox(clickLink, link);
					}

					setIsLoadingMap(false);
				})
				.finally(() => {
					gridMapContainerRef.current.classList.remove('no-transition');
					setIsLoadingMap(false);
				});
		}
	};

	const handleCircleMove = (center) => {
		if (center) {
			if (editCompanyId) {
				setGridData({
					...gridData,
					lat : center.lat,
					lng : center.lng
				});
			} else {
				let tempBusiness = { ...selectedBusiness };

				tempBusiness.lat = center.lat;
				tempBusiness.lng = center.lng;

				setSelectedBusiness(tempBusiness);

				StoreSalesBusiness(tempBusiness);
			}
		}
	};

	const generateAuditData = async () => {
		setIsGeneratingAudit(true);

		await getBusinessInfo();
		await getBusinessReviews();
		await getBusinessPhotos();
		await getBusinessPosts();

		await saveAuditData();
	};

	const getBusinessInfo = async (singleUpdate = false) => {
		if (singleUpdate) {
			setIsUpdatingAudit(true);
		}

		let data = {
			placeId : companyData.googlePlaceId,
			lat     : companyData.lat,
			lng     : companyData.lng,
			zoom    : 13
		};

		let response = await GetBusinessInfoTaskPost(data);

		if (!response || !response.success || !response.data) {
			let error = response.message || 'Could not get data';

			setAuditData({
				...auditData,
				profileInfo : { ERROR: error }
			});
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setAuditData({
					...auditData,
					profileInfo : emptyApiResponse
				});
			} else {
				let tempAuditData = {
					...auditData,
					profileInfo : response.data
				};
				setAuditData(tempAuditData);

				if (singleUpdate) {
					saveSingleAuditData(tempAuditData);
				} else {
					setTempLocalAuditData('profileInfo', response.data);
				}
			}
		}
	};

	const getBusinessReviews = async (singleUpdate = false) => {
		if (singleUpdate) {
			setIsUpdatingAudit(true);
		}

		let data = { placeId: companyData.googlePlaceId };
		let response = await GetBusinessProfileReviews(data);

		if (!response || !response.success || !response.data) {
			let error = response.message || 'Could not get data';

			setAuditData({
				...auditData,
				reviews : { ERROR: error }
			});
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setAuditData({
					...auditData,
					reviews : emptyApiResponse
				});
			} else {
				let tempAuditData = {
					...auditData,
					reviews : response.data
				};
				setAuditData(tempAuditData);

				if (singleUpdate) {
					saveSingleAuditData(tempAuditData);
				} else {
					setTempLocalAuditData('reviews', response.data);
				}
			}
		}
	};

	const getBusinessPhotos = async (singleUpdate = false) => {
		if (singleUpdate) {
			setIsUpdatingAudit(true);
		}

		let data = { placeId: companyData.googlePlaceId };
		let response = await GetBusinessProfilePhotos(data);

		if (!response || !response.success || !response.data) {
			let error = response.message || 'Could not get data';

			setAuditData({
				...auditData,
				photos : { ERROR: error }
			});
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setAuditData({
					...auditData,
					photos : emptyApiResponse
				});
			} else {
				let tempAuditData = {
					...auditData,
					photos : response.data
				};
				setAuditData(tempAuditData);

				if (singleUpdate) {
					saveSingleAuditData(tempAuditData);
				} else {
					setTempLocalAuditData('photos', response.data);
				}
			}
		}
	};

	const getBusinessPosts = async (singleUpdate = false) => {
		if (singleUpdate) {
			setIsUpdatingAudit(true);
		}

		let data = { placeId: companyData.googlePlaceId };
		let response = await GetBusinessProfilePosts(data);

		if (!response || !response.success || !response.data) {
			let error = response.message || 'Could not get data';

			setAuditData({
				...auditData,
				posts : { ERROR: error }
			});
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setAuditData({
					...auditData,
					posts : emptyApiResponse
				});
			} else {
				let tempAuditData = {
					...auditData,
					posts : response.data
				};
				setAuditData(tempAuditData);

				if (singleUpdate) {
					saveSingleAuditData(tempAuditData);
				} else {
					setTempLocalAuditData('posts', response.data);
				}
			}
		}
	};

	const saveAuditData = async () => {
		if (selectedTask) {
			let tempTask = { ...selectedTask };

			let tempLocalAuditData = localStorage.getItem('mb-temp-audit-data');
			tempLocalAuditData = tempLocalAuditData ? JSON.parse(tempLocalAuditData) : {};

			tempTask.auditData = JSON.stringify(tempLocalAuditData);

			setSelectedTask(tempTask);

			let taskResponse = await UpdateSalesCompanyTaskAuditData(tempTask.id, tempTask);

			if (taskResponse.success) {
				localStorage.removeItem('mb-temp-audit-data');
			} else {
				localStorage.removeItem('mb-temp-audit-data');
			}

			setIsGeneratingAudit(false);
		}
	};

	const saveSingleAuditData = async (data) => {
		if (selectedTask) {
			let tempTask = {
				...selectedTask,
				auditData : JSON.stringify(data)
			};

			setSelectedTask(tempTask);

			let taskResponse = await UpdateSalesCompanyTaskAuditData(tempTask.id, tempTask);

			if (taskResponse.success) {
				toast.success('Successfully updated');
			}

			setIsUpdatingAudit(false);
		}
	};

	const setTempLocalAuditData = async (auditType, data) => {
		let tempLocalAuditData = localStorage.getItem('mb-temp-audit-data');
		tempLocalAuditData = tempLocalAuditData ? JSON.parse(tempLocalAuditData) : {};
		tempLocalAuditData[auditType] = data;
		localStorage.setItem('mb-temp-audit-data', JSON.stringify(tempLocalAuditData));
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				{showGridReport ? (
					<div className="row p-3">
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<Fragment>
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								{dateCreated && (
									<div className="created-date mb-3">
										<Fragment>
											<span>Date Created:</span>
											<span className="ms-2">
												{moment(dateCreated).format('MM/DD/YYYY [@] hh:mm A [EST]')}
											</span>
										</Fragment>
									</div>
								)}

								{companyId && (
									<div className="mb-4">
										<button
											className="btn btn-link p-0 text-decoration-underline"
											onClick={() => navigate(`/grid-report-tool/${companyId}`)}
										>
											Change Keywords or Grid Size
										</button>
									</div>
								)}

								<div>
									{reportTasks &&
										reportTasks.length > 0 &&
										reportTasks.map((task, index) => (
											<div key={index} className="mb-2">
												<button
													className={`btn btn${selectedTask && selectedTask.id === task.id
														? ''
														: '-outline'}-primary btn-lg w-100 `}
													onClick={() => handleSelectReportTask(task)}
													disabled={isGenerating || isLoadingMap || reportId}
												>
													{task.keyword}
												</button>
											</div>
										))}
								</div>
							</Fragment>
						)}
					</div>
				) : (
					<Fragment>
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<div className="row p-3">
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								<form className="mt-3">
									<div className="col-12 mb-3">
										<label className="form-label">Service Area Radius</label>
										<div className="row">
											<div className="col-9 pe-0">
												<input
													type="range"
													name="areaRadius"
													className="form-range"
													value={areaRadius || ''}
													onChange={handleChange}
													min={1}
													max={15}
													step={0.5}
													disabled={isGenerating || isLoadingMap}
												/>
											</div>
											<div className="col-3 text-end">
												<span className="area-radius-value">{`${areaRadius || ''} miles`}</span>
											</div>
										</div>
									</div>
									<div className="col-12 mb-3">
										<label className="form-label">Keywords: (one per line, up to 5 terms)</label>
										<textarea
											name="keywords"
											className="form-control"
											value={keywords || ''}
											onChange={handleChange}
											disabled={isGenerating || isLoadingMap}
											rows={5}
										/>
									</div>
									<div className="col-12 text-center">
										<button
											className="btn btn-primary"
											onClick={(e) => generateReport(e)}
											disabled={isGenerating || isLoadingMap}
										>
											{isGenerating ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Generating Report...</span>
												</Fragment>
											) : (
												<span>Continue</span>
											)}
										</button>
									</div>
								</form>
							</div>
						)}
					</Fragment>
				)}

				{reportTasks &&
				reportTasks.length > 0 &&
				!isLoading && (
					<div className="grid-tool-buttons-container">
						<button
							className="btn btn-link p-0"
							onClick={() => getReportShareLink()}
							disabled={isGenerating || isLoadingMap}
						>
							<span>
								{showShareLinkCopiedMessage ? (
									<span className="bg-success text-white py-1 px-3 rounded">Copied!</span>
								) : (
									'Get Share Link'
								)}
							</span>
						</button>
						<span className="mx-2">|</span>
						<button
							className="btn btn-link p-0"
							onClick={exportMapAsImage}
							disabled={isGenerating || isLoadingMap}
						>
							Export As Image
						</button>

						<div className="mt-3">
							<Link className="btn btn-outline-primary" to="/reports">
								<i className="fa-solid fa-arrow-left-long me-2" />Reports
							</Link>
						</div>
					</div>
				)}
			</div>

			<div className="main-content-inner-container grid-tool-content-container p-2">
				<div className="map-wrapper h-100 border position-relative" ref={gridMapContainerRef}>
					<Fragment>
						{showGridReport ? (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								<ul className="nav nav-tabs" id="tabReport" role="tablist">
									<li className="nav-item" role="presentation" key="grid-report">
										<button
											className="nav-link active"
											id="grid-report-tab"
											data-bs-toggle="tab"
											data-bs-target="#grid-report-tab-pane"
											type="button"
											role="tab"
											aria-controls="grid-report-tab-pane"
											aria-selected="true"
										>
											Grid
										</button>
									</li>
									<li className="nav-item" role="presentation" key="audit-report">
										<button
											className="nav-link"
											id="audit-report-tab"
											data-bs-toggle="tab"
											data-bs-target="#audit-report-tab-pane"
											type="button"
											role="tab"
											aria-controls="audit-report-tab-pane"
											aria-selected="true"
										>
											Audit
										</button>
									</li>
								</ul>
								<div className="tab-content" id="tabReportContent">
									<div
										className="tab-pane fade show active"
										id="grid-report-tab-pane"
										role="tabpanel"
										aria-labelledby="grid-report-tab"
										tabIndex="0"
									>
										{gridData && (
											<Fragment>
												<GridMapV5
													key="map-0"
													mapIdValue="report-map-0"
													gridData={gridData}
													placeId={gridData.googlePlaceId}
													isFullHeight={true}
													reportSettings={reportSettings}
												/>
											</Fragment>
										)}
									</div>
									<div
										className="tab-pane fade"
										id="audit-report-tab-pane"
										role="tabpanel"
										aria-labelledby="audit-report-tab"
										tabIndex="0"
									>
										<div className="audit-data-container p-3">
											{/* {!auditData && ( */}
											<button
												className="btn btn-primary mb-3"
												onClick={generateAuditData}
												disabled={isGeneratingAudit}
											>
												{isGeneratingAudit ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Generating...</span>
													</Fragment>
												) : (
													<span>Generate Audit Data</span>
												)}
											</button>
											{/* )} */}

											{auditData && (
												<Fragment>
													<ul className="nav nav-tabs" id="tabAuditData" role="tablist">
														<li
															className="nav-item"
															role="presentation"
															key="business-profile"
														>
															<button
																className="nav-link active"
																id="business-profile-tab"
																data-bs-toggle="tab"
																data-bs-target="#business-profile-tab-pane"
																type="button"
																role="tab"
																aria-controls="business-profile-tab-pane"
																aria-selected="true"
															>
																Business Profile
															</button>
														</li>
														<li className="nav-item" role="presentation" key="reviews">
															<button
																className="nav-link"
																id="reviews-tab"
																data-bs-toggle="tab"
																data-bs-target="#reviews-tab-pane"
																type="button"
																role="tab"
																aria-controls="reviews-tab-pane"
																aria-selected="true"
															>
																Reviews
															</button>
														</li>
														<li className="nav-item" role="presentation" key="photos">
															<button
																className="nav-link"
																id="photos-tab"
																data-bs-toggle="tab"
																data-bs-target="#photos-tab-pane"
																type="button"
																role="tab"
																aria-controls="photos-tab-pane"
																aria-selected="true"
															>
																Photos
															</button>
														</li>
														<li className="nav-item" role="presentation" key="posts">
															<button
																className="nav-link"
																id="posts-tab"
																data-bs-toggle="tab"
																data-bs-target="#posts-tab-pane"
																type="button"
																role="tab"
																aria-controls="posts-tab-pane"
																aria-selected="true"
															>
																Posts
															</button>
														</li>
													</ul>
													<div className="tab-content border" id="tabAuditDataContent">
														<div
															className="tab-pane fade p-3 show active"
															id="business-profile-tab-pane"
															role="tabpanel"
															aria-labelledby="business-profile-tab"
															tabIndex="0"
														>
															{auditData.profileInfo && (
																<Fragment>
																	{isUpdatingAudit ? (
																		<Spinner />
																	) : (
																		<Fragment>
																			<div className="audit-update-wrapper">
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						getBusinessInfo(true)}
																				>
																					Update
																				</button>
																			</div>
																			<div className="audit-section">
																				<div className="audit-section-details">
																					<div className="audit-item">
																						<label className="form-label audit-item-label">
																							Name:
																						</label>
																						<label className="form-label audit-item-value">
																							{auditData.profileInfo
																								.title || ''}
																						</label>
																					</div>
																					<div className="audit-item">
																						<label className="form-label audit-item-label">
																							Place Id:
																						</label>
																						<label className="form-label audit-item-value">
																							{auditData.profileInfo
																								.placeId || ''}
																						</label>
																					</div>
																					<div className="audit-item">
																						<label className="form-label audit-item-label">
																							Cid:
																						</label>
																						<label className="form-label audit-item-value">
																							{auditData.profileInfo
																								.cid || ''}
																						</label>
																					</div>
																					<div className="audit-item">
																						<label className="form-label audit-item-label">
																							Lat/Lng:
																						</label>
																						<label className="form-label audit-item-value">
																							{`${auditData.profileInfo
																								.latitude},${auditData
																								.profileInfo
																								.longitude}`}
																						</label>
																					</div>
																				</div>
																			</div>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</div>
														<div
															className="tab-pane fade p-3"
															id="reviews-tab-pane"
															role="tabpanel"
															aria-labelledby="reviews-tab"
															tabIndex="0"
														>
															{auditData.reviews &&
															auditData.reviews &&
															auditData.reviews.reviews.length && (
																<Fragment>
																	{isUpdatingAudit ? (
																		<Spinner />
																	) : (
																		<Fragment>
																			<div className="audit-update-wrapper">
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						getBusinessReviews(true)}
																				>
																					Update
																				</button>
																			</div>
																			<table className="items-list audit-table table border table-responsive">
																				<thead>
																					<tr>
																						<th scope="col">User</th>
																						<th
																							className="count"
																							scope="col"
																						>
																							Rating
																						</th>
																						<th
																							className="date-time"
																							scope="col"
																						>
																							Date
																						</th>
																						<th
																							className="description"
																							scope="col"
																						>
																							Description
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{auditData.reviews.reviews.map(
																						(review, index) => {
																							return (
																								<Fragment key={index}>
																									<tr>
																										<td>
																											{
																												review
																													.user
																													.name
																											}
																										</td>
																										<td className="count">
																											{
																												review.rating
																											}
																										</td>
																										<td className="date-time">
																											{
																												review.dateString
																											}
																										</td>
																										<td
																											className="description text-truncate"
																											title={
																												review.snippet
																											}
																										>
																											{
																												review.snippet
																											}
																										</td>
																									</tr>
																								</Fragment>
																							);
																						}
																					)}
																				</tbody>
																			</table>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</div>
														<div
															className="tab-pane fade p-3"
															id="photos-tab-pane"
															role="tabpanel"
															aria-labelledby="photos-tab"
															tabIndex="0"
														>
															{auditData.photos &&
															auditData.photos &&
															auditData.photos.photos.length && (
																<Fragment>
																	{isUpdatingAudit ? (
																		<Spinner />
																	) : (
																		<Fragment>
																			<div className="audit-update-wrapper">
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						getBusinessPhotos(true)}
																				>
																					Update
																				</button>
																			</div>
																			<div
																				id="carouselAuditPhotos"
																				className="carousel slide"
																				data-bs-ride="carousel"
																			>
																				<div className="carousel-indicators">
																					{auditData.photos.photos.map(
																						(photo, index) => (
																							<button
																								type="button"
																								data-bs-target="#carouselAuditPhotos"
																								data-bs-slide-to={index}
																								className={
																									index === 0 ? (
																										'active'
																									) : (
																										''
																									)
																								}
																								key={index}
																							/>
																						)
																					)};
																				</div>
																				<div className="carousel-inner">
																					{auditData.photos.photos.map(
																						(photo, index) => (
																							<div
																								className={`carousel-item ${index ===
																								0
																									? 'active'
																									: ''}`}
																								key={index}
																							>
																								<img
																									className="d-block w-100"
																									src={photo.image}
																									alt=""
																								/>
																							</div>
																						)
																					)};
																				</div>
																			</div>
																			<button
																				className="carousel-control-prev"
																				type="button"
																				data-bs-target="#carouselAuditPhotos"
																				data-bs-slide="prev"
																			>
																				<span
																					className="carousel-control-prev-icon"
																					aria-hidden="true"
																				/>
																				<span className="visually-hidden">
																					Previous
																				</span>
																			</button>
																			<button
																				className="carousel-control-next"
																				type="button"
																				data-bs-target="#carouselAuditPhotos"
																				data-bs-slide="next"
																			>
																				<span
																					className="carousel-control-next-icon"
																					aria-hidden="true"
																				/>
																				<span className="visually-hidden">
																					Next
																				</span>
																			</button>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</div>
														<div
															className="tab-pane fade p-3"
															id="posts-tab-pane"
															role="tabpanel"
															aria-labelledby="posts-tab"
															tabIndex="0"
														>
															{auditData.posts &&
															auditData.posts &&
															auditData.posts.posts.length && (
																<Fragment>
																	{isUpdatingAudit ? (
																		<Spinner />
																	) : (
																		<Fragment>
																			<div className="audit-update-wrapper">
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						getBusinessPosts(true)}
																				>
																					Update
																				</button>
																			</div>
																			<table className="items-list audit-table table border table-responsive">
																				<thead>
																					<tr>
																						<th scope="col">User</th>
																						<th
																							className="count"
																							scope="col"
																						>
																							Rating
																						</th>
																						<th
																							className="date-time"
																							scope="col"
																						>
																							Date
																						</th>
																						<th
																							className="description"
																							scope="col"
																						>
																							Description
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{auditData.posts.posts.map(
																						(review, index) => {
																							return (
																								<Fragment key={index}>
																									<tr>
																										<td>
																											{
																												review
																													.user
																													.name
																											}
																										</td>
																										<td className="count">
																											{
																												review.rating
																											}
																										</td>
																										<td className="date-time">
																											{
																												review.dateString
																											}
																										</td>
																										<td
																											className="description text-truncate"
																											title={
																												review.snippet
																											}
																										>
																											{
																												review.snippet
																											}
																										</td>
																									</tr>
																								</Fragment>
																							);
																						}
																					)}
																				</tbody>
																			</table>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</div>
													</div>
												</Fragment>
											)}

											{/* <button className="btn btn-primary m-3" onClick={saveAuditData}>
											Save
										</button> */}
										</div>
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								{gridData && (
									<GridMapV5
										key="map-preview"
										mapIdValue="report-map-preview"
										gridData={gridData}
										placeId={gridData.googlePlaceId}
										isFullHeight={true}
										showCircle={true}
										circleRadius={areaRadius}
										onCircleMove={handleCircleMove}
										reportSettings={reportSettings}
									/>
								)}
							</Fragment>
						)}
					</Fragment>
				</div>
			</div>
		</div>
	);
};

export default GridReportTool;
